<template>
  <base-template>
  <!-- <template v-slot:content-top>
      
    </template> -->
    <div class="main-box">
      <div v-for="(item,index) in dataList" :key="index">
        <p v-if="index==0 && tips" class="content-top-remark">
          <img src="@/assets/images/stayPay/tips-icon.png" alt="">
          <span>{{ tips}}</span>
        </p>
        <p v-if="item.remark" class="remark">{{ item.remark }}</p>
        <div class="pay-data">
          <div v-if="item.payData.list.length">
            <div class="title">{{ item.payData.title }}</div>
            <ul class="list-box">
              <li v-for="(items,indexs) in item.payData.list" :key="indexs" class="list-item" @click="goLink(items.link)"><span :class="items.icon+'-icon'" />{{ items.payType }}</li>
            </ul>
          </div>
          <div v-else class="only-title" @click="goLink(item.payData.link)">{{ item.payData.title }}</div>
        </div>
      </div>
    </div>
  </base-template>
</template>

<script>
import { payTips} from '@/api/axios'
export default {
  data() {
    return {
      tips:'',
      dataList: [
        {
          remark: this.$t('userCenter.choosePayType'),
          payData: {
            title: 'Electronic wallet',
            list: [{ payType: 'GCash', link: '/gcash', icon: 'g' }, { payType: 'Paymaya', link: '/paymaya', icon: 'p' }, { payType: 'ShopeePay', link: '/shopeepay', icon: 's' }]
          }
        },
        {
          remark: '',
          payData: {
            title: '7-ELEVEN',
            link: '/eleven',
            list: []
          }
        },
        {
          remark: '',
          payData: {
            title: 'Renittance store',
            list: [{ payType: 'ML huillier', link: '/mlhuillier', icon: 'm' }, { payType: 'RD pawnshop', link: '/rdpawnshop', icon: 'r' }, { payType: 'True Money', link: '/truemoney', icon: 't' }]
          }
        },
        {
          remark: 'Can also support Cebuana offline payment',
          payData: {
            title: 'Bank',
            list: [{ payType: 'UnionBank', link: '/unionbank', icon: 'u' }, { payType: 'USSC', link: '/ussc', icon: 'u' }]
          }
        }
      ]
    }
  },

  created() {
    this.getPayTips()
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.isShowBottomTabinter(false)
    this.$H5Object.changeStatusIsDarkFont(true)
  },
  methods: {
    goLink(link) {
      console.log(link)
      this.$router.push({
        path: link
      })
    },
    async getPayTips(){
      let {data} = await payTips({})
      this.tips = data.yimbo
    },
  }
}
</script>
<style lang="scss" scoped>
.content-top-remark{
  img{
    width: 44px;
    height: 44px;
  }
  span{
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #FF8500;
    line-height: 36px;
  }
  display: flex;
  margin-right: 10px;
  margin-bottom: 32px;
}
.main-box{
  padding-top: 40px;
}
.remark{
  font-size: 24px;
  color: #CCCCCC;
  line-height: 36px;
  margin-bottom: 32px;
}
.pay-data{
  background: #F4F5FC;
  border-radius: 32px;
  padding: 40px 32px 40px 48px;
  margin-bottom: 24px;
  .title,.only-title{
    font-size: 36px;
    font-weight: bold;
    color: #005FE9;
    line-height: 52px;
    margin-bottom: 32px;
  }
  .only-title{
    margin: 0;
    position: relative;
    height: 64px;
    line-height: 64px;
    &::after{
      content: '';
      position: absolute;
      width: 48px;
      height: 64px;
      right: 0;
      background: url('~@/assets/images/stayPay/greay-next-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
  .list-box{
    .list-item{
      font-size: 28px;
      font-weight: bold;
      color: #1C1D21;
      display: flex;
      height: 64px;
      line-height: 64px;
      margin-bottom: 32px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 48px;
        height: 64px;
        right: 0;
        background: url('~@/assets/images/stayPay/greay-next-icon.png') no-repeat center center;
        background-size: 100%;
      }

      .g-icon{
        background: url('~@/assets/images/stayPay/paymethod/g-icon.png') no-repeat center center;
      }
      .p-icon{
        background: url('~@/assets/images/stayPay/paymethod/p-icon.png') no-repeat center center;
      }
      .s-icon{
        background: url('~@/assets/images/stayPay/paymethod/s-icon.png') no-repeat center center;
      }
      .m-icon{
        background: url('~@/assets/images/stayPay/paymethod/m-icon.png') no-repeat center center;
      }
      .r-icon{
        background: url('~@/assets/images/stayPay/paymethod/r-icon.png') no-repeat center center;
      }
      .t-icon{
        background: url('~@/assets/images/stayPay/paymethod/t-icon.png') no-repeat center center;
      }
      .u-icon{
        background: url('~@/assets/images/stayPay/paymethod/u-icon.png') no-repeat center center;
      }
      span{
        width: 64px;
        height: 64px;
        margin-right: 16px;
        background-size: 100%!important;
      }
    }
    .list-item:last-child{
      margin-bottom: 0;
    }
  }
}
</style>
